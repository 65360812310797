import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'

const FeaturedServices = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="features" /> 
                        Our Services
                    </span>
                    <h2>Our Featured Services</h2>
                    <p>We help you to achieve the quality of your web, API and mobile applications with our seemless automation, consulting services.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="features" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Test Automation
                                </Link>
                            </h3>
                            <p>Our paralleled automation testing services aim at reducing your repetitive tasks and manual efforts for increased efficiency and effectiveness. With our Software Testing Automation, achieving faster time to market, consistent, accurate, results.</p>

                            <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="features" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Consulting
                                </Link>
                            </h3>
                            <p>We provide a wide range of IT staffing services for entry-level, mid-level, and senior-level positions. The quality of the individuals working on a project determines its success. It takes a great deal of effort to engage and empower individuals.</p>

                            <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="features" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Our Products
                                </Link>
                            </h3>
 
                            <p>1. ACTEL is our browser automation testing platform Simple and extremely efficient solutions
for your Selenium test infrastructure. 2. Data As A Service (DAAS), 3. Test Analytics products provides seemless solutions for common automation challenges.</p>

                            <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedServices
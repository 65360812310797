import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import MainBanner from "../components/ayascloud/MainBanner"
import FeaturedServices from "../components/ayascloud/FeaturedServices"
import Partner from "../components/ayascloud/Partner"
import AboutUs from "../components/ayascloud/AboutUs"
import Services from "../components/ayascloud/Services"
import FunFacts from "../components/ayascloud/FunFacts"
import Projects from "../components/ayascloud/Projects"
import TeamMembers from "../components/ayascloud/TeamMembers"
import Testimonials from "../components/ayascloud/Testimonials"
// import Pricing from "../components/ayascloud/Pricing"
import StartProject from "../components/ayascloud/StartProject"
// import OurBlog from "../components/ayascloud/BlogPost"

const Index = () => {
    return (
        <Layout>
            <Navbar />
            <MainBanner />
            <FeaturedServices />
            <Partner />
            <AboutUs />
            <Services />
            <FunFacts />
            <Projects />
            <Testimonials />
            <TeamMembers />
            {/* <OurBlog /> */}
            {/* <Pricing /> */}
            <StartProject />
            <Footer />
        </Layout>
    );
}

export default Index
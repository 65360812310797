import React from 'react'
import aboutImg1 from '../../assets/images/about/about-img1.png'

const AboutUs = () => {
    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">About Us</span>
                    <h2>Amazing Automation, Consulting Whatever Your Needs</h2>
                    <p>Real innovations and a positive customer experience are the heart of successful automation results, products and services.</p>
                </div>

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">Test Automation Consulting</span>
                            <h2>Creative solutions, creative results</h2>
                            <p>We believe test automation is key portion in your DevOps journey. Real innovations will reslove lot of automation challenges to make successful and get maximum test coverage to eliminate bugs in your prodcut.</p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Test Automation Frameworks</h4>
                                <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Test Automation Products</h4>
                                <p>Our data and lab solutions make parallel executions faster and reliable.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Consulting Services</h4>
                                <p>We believe customers needed quality resources to get max outcome to improve their business, we committed to provide unremarakable resources to make our customers always happy.</p>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={aboutImg1} alt="about" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs
import React from 'react'
import client1 from '../../assets/images/user1.jpg'
import client2 from '../../assets/images/user2.jpg'
import client3 from '../../assets/images/user3.jpg'
import shape9 from '../../assets/images/shape/vector-shape9.png'
import shape10 from '../../assets/images/shape/vector-shape10.png'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <section className="testimonials-area bg-23173a pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                <h2>What Our Clients are Saying?</h2>
                <p> AyasCloud team feels always great about our clients testimonials, which are really boosting our motivation.</p>
                </div>

                {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 
                    <div className="single-testimonials-item">
                    <p>AyasCloud brought up great flexibility for our testing team. My entire regression test suite is now automated and runs monthly. This has saved me about three days’ worth of testing time. Now, all I wanted click on a button in Jenkins.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client1} alt="testimonial" />
                                <div className="title">
                                    <h3>Alex Maxwell</h3>
                                    <span>QA Manager</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                    <p>Awesome team we got from AyasCloud, who ramp up our QA completion time from days to an hour with highest quality strategies, I would recommend this AyasCloud in circle with the impression I got.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client2} alt="testimonial" />
                                <div className="title">
                                    <h3>David Warner</h3>
                                    <span>Principle Engineer</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                    <p>AyasCloud team is innovative and flexible in resolving challenges with their innovative thought process, which unblockes us in our CICD success journey and getting feedback on our dev work so quickly.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client3} alt="testimonial" />
                                <div className="title">
                                    <h3>Sarah Taylor</h3>
                                    <span>CEO</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''}
            </div>

            <div className="vector-shape9">
                <img src={shape9} alt="shape" />
            </div>
            <div className="vector-shape10">
                <img src={shape10} alt="shape" />
            </div>
        </section>
    )
}

export default Testimonials